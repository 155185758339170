import React, { useEffect, useState } from "react";
import { getAdminSeetings } from "../../../services/Admin";
import { HEADER_TITLE } from "../../../constant/commonConstants";

interface IAdminSettings {
  title: string;
  description: string;
  is_active: string;
}

const AdminSettings: React.FC = () => {
  const [messageData, setMessageData] = useState<IAdminSettings>();

  const gettingAdminSettings = async (title:string) => {
    const { data } = await getAdminSeetings(title);
    if (data && data.success && data.data) {
      setMessageData(data.data[0]);
    }
  };

  useEffect(() => {
    gettingAdminSettings(HEADER_TITLE);
    const interval = setInterval(() => {
      gettingAdminSettings(HEADER_TITLE);
    }, 50000);
    return () => clearInterval(interval);
  }, []);
  
  

  if (messageData && messageData.is_active==="1") {
    return (
      <div className="sub-head">
        <p className="m-0 color-white">
         {messageData?.description}
        </p>
      </div>
    );
  }
  return <> </>;
};

export default AdminSettings;
