import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ROUTES from "../../../constant/routes";

import { IAuthReducerState } from "../../../redux/reducers/AuthReducer";
import { RootState } from "../../../redux/reducers/Index";
import { getUnreadNotification } from "../../../services/user";
import CommonButton from "../../commonFields/CommonButton";

const HeaderAdmin: React.FC = () => {
  const navigate = useNavigate();
  const { t: translation } = useTranslation();

  const [unreadNotification, setUnreadNotification] = useState<number>(0);
  const authReducer: IAuthReducerState = useSelector(
    (state: RootState) => state?.AuthReducer
  );

  useEffect(() => {
    const interval = setTimeout(async () => {
      const res = await getUnreadNotification({
        userType: authReducer.authData.account_type,
      });
      if (res?.data?.success) {
        setUnreadNotification(res?.data?.data[0]?.unreadNotification);
      }
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const handleClick = () => {
    setUnreadNotification(0);
    navigate(ROUTES.NOTIFICATION);
  };

  return (
    <div className="white-card">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h3 className="color-secondary">
            <b>
              {new Date().getHours() < 12
                ? translation("good_morning")
                : new Date().getHours() < 17
                ? translation("good_afternoon")
                : translation("good_evening")}
            </b>
          </h3>
        </div>

        <CommonButton
          className="theme-button round-btn"
          type="button"
          onClick={() => handleClick()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 71.4 84"
          >
            <path
              id="notification"
              d="M39.352,2h-.3C24.9,2,11.927,12.267,10.669,25.86c-.084.972-.141,1.966-.178,3.036l-.052,2.5-.01,2.244.063-.256a16.17,16.17,0,0,1-3.9,7.647l-.282.363a17.136,17.136,0,0,0-2.8,8.585l0,1A17.1,17.1,0,0,0,7.392,62.154a20.472,20.472,0,0,0,12.963,6.4,174.648,174.648,0,0,0,37.692,0,20.155,20.155,0,0,0,12.8-6.248A17.119,17.119,0,0,0,74.9,51.82l0-1.694a16.856,16.856,0,0,0-2.82-8.711l-.259-.333-.633-.745a16.505,16.505,0,0,1-3.052-6.012l-.187-.786-.039-.824c-.012-.371-.017-.782-.019-1.3V28.595c-.01-1.081-.042-1.85-.116-2.716C66.472,12.26,53.494,2,39.352,2Zm-.3,5.86h.3c11.33,0,21.665,8.171,22.657,18.555.059.682.085,1.332.093,2.3l.011,3.8c.013.608.037,1.113.077,1.682l.054.389.273,1.158a22.391,22.391,0,0,0,4.255,8.387l.579.679-.064-.107a10.859,10.859,0,0,1,1.827,5.556l0,.687a11.142,11.142,0,0,1-2.575,7.447,14.354,14.354,0,0,1-9.059,4.328,169.139,169.139,0,0,1-36.539,0,14.638,14.638,0,0,1-9.232-4.474,11.157,11.157,0,0,1-2.413-7.206v-.919l.073-.8a11.217,11.217,0,0,1,1.317-3.944l.352-.582a22.051,22.051,0,0,0,5.125-10.222l.06-.6.027-3.861.067-1.959c.031-.618.07-1.205.119-1.774C17.394,16.032,27.719,7.86,39.052,7.86ZM11.035,44.8l-.188.21.207-.24ZM50.154,76.3a2.871,2.871,0,0,0-4.07.446,8.3,8.3,0,0,1-1.5,1.459,8.981,8.981,0,0,1-6.62,1.87,8.89,8.89,0,0,1-5.938-3.318,2.871,2.871,0,0,0-4.068-.466,2.955,2.955,0,0,0-.46,4.118A14.682,14.682,0,0,0,48.09,82.871a13.956,13.956,0,0,0,2.5-2.451A2.955,2.955,0,0,0,50.154,76.3Z"
              transform="translate(-3.5 -2)"
              fillRule="evenodd"
              opacity="1"
              fill="#FF9494"
            />
          </svg>
          {unreadNotification > 0 && <span className="notification-show" />}
        </CommonButton>
      </div>
    </div>
  );
};

export default HeaderAdmin;
