import * as yup from "yup";
import { TFunction } from "react-i18next";

import {
  ALPHABATE_WITH_SPACE_REGEX,
  NAME_REGEX,
  NUMBER_REGEX,
  NUMBER_REGEX_WITH_SPACE,
  PASSWORD_REGEX,
  ALPHANUMERIC_REGEX,
  ADDRESS_REGEX,
} from "../constant/commonConstants";

/**
 * Handle validation for email field
 * @param translate i18n translate hook instance
 * @returns
 */
export const email = (translate: TFunction) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", { field_label: translate("email") })
    )
    .email(
      translate("invalid_field_error", { field_name: translate("email") })
    );

/**
 * Common validation schema
 * @param translate i18n translate hook instance
 * @returns
 */
export const zip = (translate: TFunction) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", {
        field_label: translate("zip"),
      })
    )
    .matches(
      NUMBER_REGEX,
      translate("invalid_field_error", {
        field_name: translate("zip"),
      })
    )
    .min(5, translate("min_length", { length: 5, length_type: "digits" }))
    .max(5, translate("max_length", { length: 5, length_type: "digits" }))
    .test(
      "len",
      translate("invalid_field_error", {
        field_name: translate("zip"),
      }),
      (val?: string) => (val ? val.length === 5 : false)
    );

export const name = (translate: TFunction) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", { field_label: translate("name") })
    )
    .matches(NAME_REGEX, translate("contains_only_alphanumeric"))
    .max(25, translate("max_len_25"));

/**
 * Validate the string field for only alphabates
 * @param fieldName field name
 */
export const alphabates = (translate: TFunction, fieldName: string) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", { field_label: translate(fieldName) })
    )
    .matches(
      ALPHABATE_WITH_SPACE_REGEX,
      translate("only_contain", {
        field_name: translate(fieldName),
        type: translate("alphabates"),
      })
    );

/**
 * Validate the string field for only alphaNumeric values
 * @param fieldName field name
 */
export const alphaNumeric = (translate: TFunction, fieldName: string) =>
  yup
    .string()
    .required(
      translate("required_field_error", { field_label: translate(fieldName) })
    )
    .matches(
      ALPHANUMERIC_REGEX,
      translate("invalid_field_error", {
        field_name: translate(fieldName),
      })
    );

/**
 * Handle validation for city field
 * @param translate i18n translate hook instance
 * @returns
 */
export const city = (translate: TFunction) =>
  yup
    .string()
    .required(
      translate("required_field_error", { field_label: translate("city") })
    )
    .test(
      "isNumberSpace",
      translate("contains_only_alphanumeric"),
      (value) => !NUMBER_REGEX_WITH_SPACE.test(value as string)
    )
    .max(25, translate("max_len_25"));

/**
 * Handle validation for state field
 * @param translate i18n translate hook instance
 * @returns
 */
export const state = (translate: TFunction) =>
  yup
    .string()
    .required(
      translate("required_field_error", { field_label: translate("state") })
    )
    .matches(ALPHABATE_WITH_SPACE_REGEX, translate("contains_only_alphabets"))
    .max(50, translate("max_len_50"));

/**
 * Handle validation for password field
 * @param translate i18n translate hook instance
 * @returns
 */
export const password = (translate: TFunction) =>
  yup
    .string()
    .required(
      translate("required_field_error", {
        field_label: translate("Password"),
      })
    )
    .matches(PASSWORD_REGEX, translate("pass_reg_ms"));

/**
 * Handle validation for terms and conditions field
 * @param translate i18n translate hook instance
 * @returns
 */
export const termsConditions = (translate: TFunction) =>
  yup
    .boolean()
    .default(false)
    .oneOf(
      [true],
      translate("required_field_error", {
        field_label: translate("terms_condition"),
      }).replace("is","are")
    );

export const messageTermCondition = (translate: TFunction) =>
  yup
    .boolean()
    .default(false)
    .oneOf(
      [true],
      translate("required_field_error", {
        field_label: "This",
      })
    );
/**
 * Handle validation for address field
 * @param translate i18n translate hook instance
 * @returns
 */
export const addressLine1 = (translate: TFunction) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", {
        field_label: translate("address_line", { no: 1 }),
      })
    )
    .matches(
      ADDRESS_REGEX,
      `${translate("contains_only_alphanumeric")} ${translate(
        "including"
        // eslint-disable-next-line no-useless-escape
      )} , . _ ' - / \\ " `
    )
    .max(100, translate("max_len_50"));
/**
 * Handle validation for address field
 * @param translate i18n translate hook instance
 * @returns
 */
export const addressLine2 = (translate: TFunction) =>
  yup
    .string()
    .trim()
    .matches(
      ADDRESS_REGEX,
      `${translate("contains_only_alphanumeric")} ${translate(
        "including"
        // eslint-disable-next-line no-useless-escape
      )} , . _ ' - / \\ " `
    )
    .max(50, translate("max_len_50"));

/**
 * Validate the string field for only numeric values
 * @param fieldName field name
 */
export const numeric = (translate: TFunction, fieldName: string) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", { field_label: translate(fieldName) })
    )
    .matches(
      NUMBER_REGEX,
      translate("invalid_field_error", {
        field_name: translate(fieldName),
      })
    );

/**
 * Validate the string field for only non empty alphaNumeric values
 * @param fieldName field name
 */
export const nonEmptyAlphaNumeric = (translate: TFunction, fieldName: string) =>
  yup
    .string()
    .trim()
    .matches(
      ALPHANUMERIC_REGEX,
      translate("invalid_field_error", {
        field_name: translate(fieldName),
      })
    );

/**
 * Validate the string field for empty
 * @param fieldName
 */
export const emptyField = (translate: TFunction, fieldName: string) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", { field_label: translate(fieldName) })
    );

/**
 * Handle validation for phone field
 * @param translate i18n translate hook instance
 * @returns
 */
export const phone = (translate: TFunction) =>
  yup
    .string()
    .required(translate("phone_req"))
    .min(10, translate("invalid_phone"))
    .max(10, translate("invalid_phone"));

export const companyName = (translate: TFunction ,from?:string) =>{
  if(from && from==="profile"){
    return yup.string().required(
      translate("required_field_error", {
        field_label: translate("company_name"),
      })
    ).trim().nullable(true).max(30, translate("max_len_30"));
  }
 return yup.string().trim().nullable(true).max(30, translate("max_len_30"));
}

/**
 * Handle validation for phone field
 * @param translate i18n translate hook instance
 * @returns
 */
export const ssn = (translate: TFunction) =>
  yup
    .string()
    .required(translate("ssn_req"))
    .min(9, translate("invalid_ssn"))
    .max(9, translate("invalid_ssn"))
    .matches(NUMBER_REGEX, translate("invalid_ssn"));

export const firstName = (translate: TFunction) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", {
        field_label: translate("first_name"),
      })
    )
    .matches(NAME_REGEX, translate("contains_only_alphanumeric"))
    .max(25, translate("max_len_25"));

export const lastName = (translate: TFunction) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", {
        field_label: translate("last_name"),
      })
    )
    .matches(NAME_REGEX, translate("contains_only_alphanumeric"))
    .max(25, translate("max_len_25"));

export const Subject = (translate: TFunction) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", {
        field_label: translate("subject"),
      })
    )
    .matches(NAME_REGEX, translate("contains_only_alphanumeric"))
    .max(50, translate("max_len_50"));

/**
 * Handle validation for login password field
 * @param translate i18n translate hook instance
 * @returns
 */
export const loginPassword = (translate: TFunction) =>
  yup.string().required(
    translate("required_field_error", {
      field_label: translate("Password"),
    })
  );
